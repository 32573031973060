import { toast } from "react-toastify"
import ErrorToast from "../utility/componentsToast/ErrorToast"
import axios from 'axios'
import { store } from '../redux/store'
import { logout } from "../redux/authentication"
import { environment } from "./endpointsBase"
function showToastWithMessage(message, autoClose = false) {
  toast(<ErrorToast error={message} />, {
    autoClose: autoClose ? 5000 : false,
    hideProgressBar: true
  })
}
export function handleWithError422(error) {
  Object.entries(error.response.data).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach(item => {
        showToastWithMessage(item.message)
      })
    } else if (typeof value === "object" && value !== null) {
      Object.values(value).forEach(subValue => {
        if (Array.isArray(subValue)) {
          subValue.forEach(message => {
            showToastWithMessage(message)
          })
        }
      })
    } else {
      showToastWithMessage(value)
    }
  })
}

export function handleWithError400(error) {
  if (error.response.request.responseURL !== `${environment.entrada}/upload/xml` && error.response.request.responseURL !== `${environment.entrada}/upload/xml/1`) {
    const data = Object.keys(error.response.data)
    for (let index = 0; index < data.length; index++) {
      const element = data[index]
      toast(<ErrorToast error={error.response.data[element]} />, {
        autoClose: 1000 * 5,
        hideProgressBar: true
      })
    }
  }
}

export function handleWithError401(error) {
  if (error.response?.status === 401 && error.response.request.responseURL !== `${environment.entrada}/auth/maintenance` && error.response.request.responseURL !== `${environment.entrada}/auth/refresh`) {
    document.cookie = `admin=true; path=/; max-age=0;`
    window.location = '/login'
    axios.isCancel(error)
    store.dispatch(logout())
  }
}

export function handleWithError429(error) {
  if (error.response?.status === 429 && error.response.request.responseURL === `${environment.entrada}/auth/refresh`) {
    axios.isCancel(error)
    store.dispatch(logout())
    window.location = '/login'
  } else {
    toast(<ErrorToast error='Você fez muitos pedidos, aguarde um momento!' />, {
      hideProgressBar: true,
      toastId: 'pedidos'
    })
  }
}
