import { createContext, useState } from "react"

export const UtilsContext = createContext({})

export function UtilsProvider(props) {
  const [tipoArquivo, setTipoArquivo] = useState('')
  const [idProduto, setIdProduto] = useState('')
  const [reload, setReload] = useState(false)
  const [nfe, setNfe] = useState('')
  const [tipoGuia, setTipoGuia] = useState('')

  const [ahaSimulador, setAhaSimulador] = useState(false)
  const [ahaCalculo, setahaCalculo] = useState(false)

  const [linkPostal, setLinkPostal] = useState('')
  function handleType(value) {
    setTipoArquivo(value)
  }

  function handleIdProduto(value) {
    setIdProduto(value)
  }

  function handleReload() {
    setReload(!reload)
  }
  function handleGuia(value) {
    setTipoGuia(value)
  }
  function handleAhaCalculo(value) {
    setahaCalculo(value)
  }
  function handleAhaSimulador(value) {
    setAhaSimulador(value)
  }

  function handleLinkPostal(value) {
    setLinkPostal(value)
  }

  return (
    <UtilsContext.Provider value={{tipoArquivo, handleType, handleIdProduto, idProduto, handleReload, reload, nfe, setNfe, handleGuia, tipoGuia, handleAhaSimulador, handleAhaCalculo, ahaCalculo, ahaSimulador, handleLinkPostal, linkPostal}}>
      {props.children}
    </UtilsContext.Provider>
  )
}